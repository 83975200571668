import React from "react";
import { AGENT_TOKEN_QUERY, SIGN_IN_PATH } from "../constants/routes";
import { Redirect, useLocation } from "react-router-dom";
import AppContext from "../context/AppContext";
import Loader from "./Loader";

// For routes that can only be accessed by authenticated users
function TokenGuard(props: any) {
    const { children } = props;
    const appContext = React.useContext(AppContext);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get(AGENT_TOKEN_QUERY);

    if ( !appContext.loading) {
    // validate token
    if (!token && !appContext.isAuthenticated) {
        return <Redirect to={SIGN_IN_PATH} />;
    }

    return children;
} else {
    return (<Loader />)
}
}

export default TokenGuard;